import { Dispatch, SetStateAction } from "react";
import { Container, Dialog } from "@mui/material";
import {
  FertilizerEvent,
  FertilizerEventUpdateActionBody,
  updateFertilizerEvent,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import {
  FertilizerEventFormData,
  convertFromFormInput,
} from "./FertilizerEventFormData";
import EnterFertilizerEvent from "./EnterFertilizerEvent";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function UpdateFertilizerEvent({
  fertilizerEvent,
  dialogOpen,
  setDialogOpen,
}: {
  fertilizerEvent: FertilizerEvent;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const handleClose = () => {
    setDialogOpen(false);
    mixpanel.track("Update fertilizer event cancelled");
  };

  async function handleSave(
    fertilizerEventFormData: FertilizerEventFormData,
    farmGuid: string | undefined
  ) {
    const fertilizerEventBody = convertFromFormInput(fertilizerEventFormData);
    const postBody: FertilizerEventUpdateActionBody = {
      ...fertilizerEventBody,
      fieldGuid: fertilizerEventFormData.fieldGuids[0],
    };
    await dispatch(
      updateFertilizerEvent({
        fertilizerEvent: { ...fertilizerEvent, ...postBody },
      })
    );
    notify(t("updateFertilizerEvent.add.success"));
    mixpanel.track("Fertilizer event updated");
    handleClose();
  }
  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={dialogOpen}
      onClose={handleClose}
    >
      <StyledContainer>
        {
          <EnterFertilizerEvent
            fertilizerEvent={fertilizerEvent}
            handleSave={handleSave}
            handleClose={() => {
              handleClose();
            }}
            fieldSelectDisabled={true}
          />
        }
      </StyledContainer>
    </Dialog>
  );
}

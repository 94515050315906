import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
  EntityState,
  createAction,
} from "@reduxjs/toolkit";
import { center, Feature, FeatureCollection, Point } from "@turf/turf";
import API from "common/utils/Api";
import { RootState } from "model";
import { mixpanel } from "common/analytics";
import { multiPolygonToPolygon, polygonToMultiPolygon } from "common/utils";
import { selectTmpDeletedFeatures } from "./appSlice";

const arableFieldApi = new API<ArableFieldResponse>("fields");

export enum ARABLE_TYPE {
  WHEAT = "WHEAT",
  BARLEY = "BARLEY",
  OILSEED_RAPE = "OILSEED_RAPE",
  POTATO = "POTATO",
  MAIZ = "MAIZ",
  CEREALS = "CEREALS",
  VEGETABLES_SALAD = "VEGETABLES_SALAD",
  OTHERS = "OTHERS",
}

export enum FIELD_TYPE {
  GRASS = "GRASS",
  ARABLE = "ARABLE",
}

type Visibility = "none" | "visible";

export type ArableFieldResponse = {
  guid: string;
  name: string;
  farm: string;
  geom: FeatureCollection;
  area: number;
  tiles?: Array<string>;
  arableType: ARABLE_TYPE;
};

export type ArableField = {
  guid: string;
  name: string;
  farm: string;
  geom: Feature;
  area: number;
  invalidatedTimestamp?: number;
  tiles?: Array<string>;
  visibility?: Visibility;
  arableType: ARABLE_TYPE;
};

export type ArableFieldUpdate = {
  guid: string;
  name?: string;
  farm?: string;
  geom?: Feature;
  area?: number;
  invalidatedTimestamp?: number;
  visibility?: Visibility;
};

export const prepareArableFieldRequest = (field: ArableField) => {
  return {
    ...field,
    fieldType: FIELD_TYPE.ARABLE,
    geom: {
      type: "FeatureCollection",
      features: field.geom && [polygonToMultiPolygon(field.geom)],
    } as FeatureCollection,
  };
};

export const processArableFieldResponse = (
  fields: Array<ArableFieldResponse>
) => {
  return fields.map((field: ArableFieldResponse) => {
    const area = field.geom.features[0].properties?.area;
    const geom = multiPolygonToPolygon(field.geom.features[0]) as Feature;
    const f: ArableField = {
      ...field,
      visibility: "visible",
      area: area,
      geom,
    };
    return f;
  });
};

export const arableFieldAdapter = createEntityAdapter<ArableField>({
  // we need this because IDs are stored in a arableField other than `field.id`
  selectId: (arableField) => arableField.guid,
  // Keeps the array sorted by guid
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const {
  selectAll: selectAllArableFields,
  selectById: selectArableFieldById,
  selectEntities: selectArableFieldEntities,
  selectIds: selectArableFieldIds,
} = arableFieldAdapter.getSelectors((state: RootState) => state.arableFields);

//TODO: compute on backend
export const mapArableFieldsCentersById = createSelector(
  selectAllArableFields,
  (arableFields) => {
    const centerByGuid: Record<string, Feature<Point>> = {};

    arableFields.forEach((arableField) => {
      centerByGuid[arableField.guid] = center(arableField.geom);
    });

    return centerByGuid;
  }
);

export const getAllInvalidatedArableFields = createSelector(
  selectAllArableFields,
  (arableFields) => {
    return arableFields.filter(
      (arableField) => arableField.invalidatedTimestamp
    );
  }
);

export const getAllArableFieldGeoms = createSelector(
  selectAllArableFields,
  (arableFields) => {
    const features = arableFields.map((f) => f.geom);
    return {
      type: "FeatureCollection",
      features: features,
    } as FeatureCollection;
  }
);

export const getTotalArableArea = createSelector(
  selectAllArableFields,
  (arableFields) =>
    arableFields.reduce((acc, arableField) => {
      if (!arableField.invalidatedTimestamp) {
        acc += arableField.area / 10000;
      }
      return acc;
    }, 0)
);

export const getLatestArableFieldTiles = createSelector(
  selectAllArableFields,
  (arableFields) => {
    const tileIds = arableFields.map((f) => {
      return f.tiles?.slice(-1)[0];
    });
    return tileIds.filter((t) => t);
  }
);

export const getArableFieldsWithNoTiles = createSelector(
  selectAllArableFields,
  (arableFields) =>
    arableFields.reduce((acc: Array<string>, arableField) => {
      if (arableField.tiles?.length === 0) {
        acc.push(arableField.guid);
      }
      return acc;
    }, [])
);

// hide features that are being edited and boundary removed;
export const getArableFieldsWithNoTilesForNotDeletedFieldBoundaries =
  createSelector(
    getArableFieldsWithNoTiles,
    selectTmpDeletedFeatures,
    (arableFields, tmpDeletedFeatures) =>
      arableFields.filter((id) => tmpDeletedFeatures.indexOf(id) === -1)
  );

export const getArableFieldsCenterWithNoTilesForNotDeletedFieldBoundaries =
  createSelector(
    selectAllArableFields,
    selectTmpDeletedFeatures,
    mapArableFieldsCentersById,
    (arableFields, tmpDeletedFeatures, arableFieldCentersById) => {
      return arableFields
        .filter(
          (arableField) =>
            arableField.tiles?.length === 0 &&
            tmpDeletedFeatures.indexOf(arableField.guid) === -1
        )
        .map((arableField) => ({
          guid: arableField.guid,
          center: arableFieldCentersById[arableField.guid],
        }));
    }
  );

// hide features that are being edited and boundary removed;
export const selectAllArableFieldsWithNotDeletedBoundaries = createSelector(
  selectAllArableFields,
  selectTmpDeletedFeatures,
  (arableFields, tmpDeletedFeatures) =>
    arableFields.filter(
      (arableField) => tmpDeletedFeatures.indexOf(arableField.guid) === -1
    )
);

export const fetchArableFieldsByFarmId = createAsyncThunk(
  "arableFields/fetchByFarmId",
  async (farmId: string, { dispatch }) => {
    const ids = await arableFieldApi.getIds(
      new URLSearchParams({
        farmId: farmId,
        fieldType: "ARABLE,FALLOW",
      })
    );

    if (ids.length > 0) {
      return dispatch(fetchArableFieldsById(ids));
    }
  }
);

export const fetchArableFieldsById = createAsyncThunk<
  Promise<Array<ArableFieldResponse>>,
  Array<string>
>("arableFields/fetchById", (arableFieldIds: Array<string>) =>
  arableFieldApi.getMany(arableFieldIds)
);

type CreateArableFieldThunkInput = {
  arableField: ArableField;
};

export const createArableField = createAsyncThunk<
  Promise<ArableFieldResponse>,
  CreateArableFieldThunkInput
>("arableFields/create", async ({ arableField }) => {
  const res = await arableFieldApi.createOne(
    prepareArableFieldRequest(arableField)
  );

  const newField = await arableFieldApi.getOne(res.guid);

  return newField;
});

export const updateArableField = createAsyncThunk<
  Promise<ArableFieldResponse>,
  ArableField
>("arableFields/update", async (arableField, { dispatch }) => {
  const res = await arableFieldApi.updateOne(
    prepareArableFieldRequest(arableField)
  );
  dispatch(
    arableFieldSlice.actions.changeArableFieldVisibility({
      guid: arableField.guid,
      visibility: "visible",
    })
  );
  return arableFieldApi.getOne(arableField.guid);
});

const makeTileInvisible = createAction<string>("tile/madeInvisible");

export const deleteArableField = createAsyncThunk<Promise<number>, ArableField>(
  "arableFields/delete",
  async (arableField, { dispatch }) => {
    const res = await arableFieldApi.deleteOne(arableField.guid);
    if (res.ok) {
      dispatch(arableFieldSlice.actions.removeArableField(arableField.guid));
      arableField.tiles?.forEach((tileId) =>
        dispatch(makeTileInvisible(tileId))
      );
    }
    return res.status;
  }
);

export const getArableFieldCount = createSelector(
  selectAllArableFields,
  (arableFields) => arableFields.length
);

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";

type ArableFieldState = EntityState<ArableField> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialState: ArableFieldState = arableFieldAdapter.getInitialState({
  loading: "idle",
  error: null,
  status: null,
});

const arableFieldSlice = createSlice({
  name: "arableFields",
  initialState,
  reducers: {
    resetArableFields: () => initialState,
    changeArableFieldVisibility: (
      state,
      action: PayloadAction<ArableFieldUpdate>
    ) => {
      if (state.entities[action.payload.guid]) {
        state.entities[action.payload.guid]!.visibility =
          action.payload.visibility;
      }
    },
    removeArableField: (state, action: PayloadAction<string>) =>
      arableFieldAdapter.removeOne(state, action.payload),
    invalidateArableFieldData: (state, action: PayloadAction<string>) => {
      mixpanel.track("ArableField invalidated");
      mixpanel.time_event("ArableField validated");
      arableFieldAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          invalidatedTimestamp: Date.now() / 1000,
          tiles: [],
        },
      });
    },
    validateArableFieldData: (state, action: PayloadAction<string>) => {
      mixpanel.track("ArableField validated", { guid: action.payload }); // stop the timer
      mixpanel.track("ArableField validation timeout", {
        guid: action.payload,
      });
      arableFieldAdapter.updateOne(state, {
        id: action.payload,
        changes: { invalidatedTimestamp: undefined },
      });
    },
  },
  extraReducers: {
    [fetchArableFieldsById.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [fetchArableFieldsById.fulfilled.type]: (
      state,
      action: PayloadAction<Array<ArableFieldResponse>>
    ) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
        action.payload.forEach((f) => {
          if (state.entities[f.guid]?.invalidatedTimestamp) {
            if (f.tiles && f.tiles.length > 0) {
              delete state.entities[f.guid]?.invalidatedTimestamp;
              mixpanel.track("ArableField validated", { guid: f.guid });
            }
          }
        });

        // Always update arableFields with the latest data, even if they have no tiles
        arableFieldAdapter.upsertMany(
          state,
          processArableFieldResponse(action.payload)
        );
      }
    },
    [fetchArableFieldsById.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
        state.status = "error";
      }
    },
    [createArableField.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createArableField.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
        arableFieldAdapter.upsertMany(
          state,
          processArableFieldResponse([action.payload as ArableFieldResponse])
        );
      }
    },
    [createArableField.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
    [updateArableField.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
      if (state.entities[action.meta.arg.guid]) {
        // optimistic update of the geometry
        state.entities[action.meta.arg.guid]!.geom = action.meta.arg.geom;
      }
    },
    [updateArableField.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
      }
      arableFieldAdapter.upsertMany(
        state,
        processArableFieldResponse([action.payload as ArableFieldResponse])
      );
    },
    [updateArableField.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
    [deleteArableField.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [deleteArableField.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
      }
    },
    [deleteArableField.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
        state.status = "error";
      }
    },
  },
});

export const {
  changeArableFieldVisibility,
  invalidateArableFieldData,
  validateArableFieldData,
  resetArableFields,
} = arableFieldSlice.actions;

export const arableFieldReducer = arableFieldSlice.reducer;

import { MACHINERY } from "./commonTypes";

export enum ORGANIC_FERTILIZER_ANIMAL_TYPES {
  DAIRY_CATTLE = "DAIRY_CATTLE",
  BEEF_CATTLE = "BEEF_CATTLE",
  PIG = "PIG",
  SHEEP = "SHEEP",
  HORSE = "HORSE",
  CHICKEN_LITTER = "CHICKEN_LITTER",
}

export enum ORGANIC_FERTILIZER_TYPES {
  FYM = "FYM",
  SLURRY = "SLURRY",
  DIRTY_WATER = "DIRTY_WATER",
}

export enum FERTILIZER_TYPES {
  ORGANIC = "ORGANIC",
  SYNTHETIC = "SYNTHETIC",
  LIME = "LIME",
  BIOSOLIDS = "BIOSOLIDS",
  BIODIGESTATE = "BIODIGESTATE",
  COMPOST = "COMPOST",
  OTHER = "OTHER",
}

export enum ORGANIC_FERTILIZER_APPLICATION_METHOD {
  DISC_INJECTOR = "DISC_INJECTOR",
  TRAILING_SHOE = "TRAILING_SHOE",
  DRIBBLE_BAR = "DRIBBLE_BAR",
  SPLASH_PLATE = "SPLASH_PLATE",
  OTHER = "OTHER",
}

export enum FERTILIZER_TOTAL_AMOUNT_UNIT {
  KG = "KG",
  TONNE = "TONNE",
  M3 = "M3",
  GALLONS = "GALLONS",
}

export enum NITROGEN_FERTILIZER_TYPE {
  UREA = "UREA",
  PROTECTED_UREA = "PROTECTED_UREA",
  UAN = "UAN",
  CAN = "CAN",
  CN = "CN",
  AN = "AN",
  OTHER = "OTHER",
}

export type OrganicFertilizerApplicationEvent1Payload = {
  fertilizerType: FERTILIZER_TYPES.ORGANIC;
  isImported: boolean;
  organicFertilizerType: ORGANIC_FERTILIZER_TYPES;
  animalType: ORGANIC_FERTILIZER_ANIMAL_TYPES[];
  applicationMethod: ORGANIC_FERTILIZER_APPLICATION_METHOD;
};

export type SyntheticFertilizerApplicationEvent1Payload = {
  fertilizerType: FERTILIZER_TYPES.SYNTHETIC;
  productName: string;
  nPercentage: number;
  typeOfNitrogen: NITROGEN_FERTILIZER_TYPE;
  pPercentage?: number;
  kPercentage?: number;
};

export type LimeApplicationEvent1Payload = {
  fertilizerType: FERTILIZER_TYPES.LIME;
  phPriorToApplication: number;
};

export type BiosolidsApplicationEvent1Payload = {
  fertilizerType: FERTILIZER_TYPES.BIOSOLIDS;
};

export type BiodigestateApplicationEvent1Payload = {
  fertilizerType: FERTILIZER_TYPES.BIODIGESTATE;
};

export type CompostApplicationEvent1Payload = {
  fertilizerType: FERTILIZER_TYPES.COMPOST;
};

export type OtherApplicationEvent1Payload = {
  fertilizerType: FERTILIZER_TYPES.OTHER;
};

export type FertilizerEvent = {
  guid: string;
  fertilizerApplicationDate: string;
  creationDate: string;
  fieldGuid: string;
  note?: string | null;
  totalAmount: number;
  totalAmountUnit: FERTILIZER_TOTAL_AMOUNT_UNIT;
  fuel?: number;
  machinery?: MACHINERY[] | null;
  fertilizerProperties:
    | OrganicFertilizerApplicationEvent1Payload
    | SyntheticFertilizerApplicationEvent1Payload
    | LimeApplicationEvent1Payload
    | BiosolidsApplicationEvent1Payload
    | BiodigestateApplicationEvent1Payload
    | CompostApplicationEvent1Payload
    | OtherApplicationEvent1Payload;
};

export type FertilizerEventGetActionQuery = {
  farmGuid: string;
  fertilizerApplicationTimeAfter?: string;
};

export type FertilizerEventBody = {
  fertilizerApplicationDate: string;
  note?: string | null;
  totalAmount: number;
  totalAmountUnit: FERTILIZER_TOTAL_AMOUNT_UNIT;
  fuel?: number;
  machinery?: MACHINERY[] | null;
  fertilizerProperties:
    | OrganicFertilizerApplicationEvent1Payload
    | SyntheticFertilizerApplicationEvent1Payload
    | LimeApplicationEvent1Payload
    | BiosolidsApplicationEvent1Payload
    | BiodigestateApplicationEvent1Payload
    | CompostApplicationEvent1Payload
    | OtherApplicationEvent1Payload;
};

export type FertilizerEventPostActionBody = FertilizerEventBody & {
  fieldGuids: string[];
};

export type FertilizerEventUpdateActionBody = FertilizerEventBody & {
  fieldGuid: string;
};

import {
  MACHINERY,
  FERTILIZER_TYPES,
  FERTILIZER_TOTAL_AMOUNT_UNIT,
  ORGANIC_FERTILIZER_TYPES,
  ORGANIC_FERTILIZER_ANIMAL_TYPES,
  ORGANIC_FERTILIZER_APPLICATION_METHOD,
  NITROGEN_FERTILIZER_TYPE,
  BiodigestateApplicationEvent1Payload,
  BiosolidsApplicationEvent1Payload,
  CompostApplicationEvent1Payload,
  FertilizerEventBody,
  LimeApplicationEvent1Payload,
  OrganicFertilizerApplicationEvent1Payload,
  OtherApplicationEvent1Payload,
  SyntheticFertilizerApplicationEvent1Payload,
} from "model";

export type OrganicFertilizerApplicationEvent = {
  isImported: boolean;
  organicFertilizerType: ORGANIC_FERTILIZER_TYPES;
  animalType: ORGANIC_FERTILIZER_ANIMAL_TYPES[];
  applicationMethod: ORGANIC_FERTILIZER_APPLICATION_METHOD;
};

export type SyntheticFertilizerApplicationEvent = {
  productName: string;
  nPercentage: number;
  typeOfNitrogen: NITROGEN_FERTILIZER_TYPE;
  pPercentage?: number;
  kPercentage?: number;
};

export type LimeApplicationEvent = {
  phPriorToApplication: number;
};

export type AnyOtherFertilizerEvent = Record<string, never>;

export type FertilizerEventFormData = {
  note?: string;
  machinery?: MACHINERY[];
  totalAmount: number;
  totalAmountUnit: FERTILIZER_TOTAL_AMOUNT_UNIT;
  fuel?: number;
  fertilizerApplicationDate: Date;
  fertilizerType: FERTILIZER_TYPES;
  fieldGuids: string[];
  fertilizerProperties:
    | OrganicFertilizerApplicationEvent
    | SyntheticFertilizerApplicationEvent
    | LimeApplicationEvent
    | AnyOtherFertilizerEvent;
};
export const convertFromFormInput = (
  fertilizerEventFormData: FertilizerEventFormData
): FertilizerEventBody => {
  let fertilizerProperties = {};
  if (fertilizerEventFormData.fertilizerType === FERTILIZER_TYPES.SYNTHETIC) {
    const syntheticFertilizerProperties =
      fertilizerEventFormData.fertilizerProperties as SyntheticFertilizerApplicationEvent;
    fertilizerProperties = {
      kPercentage: syntheticFertilizerProperties.kPercentage
        ? syntheticFertilizerProperties.kPercentage / 100
        : undefined,
      nPercentage: syntheticFertilizerProperties.nPercentage / 100,
      pPercentage: syntheticFertilizerProperties.pPercentage
        ? syntheticFertilizerProperties.pPercentage / 100
        : undefined,
      productName: syntheticFertilizerProperties.productName,
      typeOfNitrogen: syntheticFertilizerProperties.typeOfNitrogen,
    };
  } else if (
    fertilizerEventFormData.fertilizerType === FERTILIZER_TYPES.ORGANIC
  ) {
    const organicFertilizerProperties =
      fertilizerEventFormData.fertilizerProperties as OrganicFertilizerApplicationEvent;
    fertilizerProperties = {
      animalType: organicFertilizerProperties.animalType,
      applicationMethod: organicFertilizerProperties.applicationMethod,
      isImported: organicFertilizerProperties.isImported,
      organicFertilizerType: organicFertilizerProperties.organicFertilizerType,
    };
  } else if (fertilizerEventFormData.fertilizerType === FERTILIZER_TYPES.LIME) {
    const limeFertilizerProperties =
      fertilizerEventFormData.fertilizerProperties as LimeApplicationEvent;
    fertilizerProperties = {
      phPriorToApplication: limeFertilizerProperties.phPriorToApplication,
    };
  }
  return {
    note: fertilizerEventFormData.note,
    machinery: fertilizerEventFormData.machinery,
    fuel:
      (fertilizerEventFormData.fuel as unknown as string) === ""
        ? undefined
        : fertilizerEventFormData.fuel,
    fertilizerProperties: {
      ...fertilizerProperties,
      fertilizerType: fertilizerEventFormData.fertilizerType,
    } as
      | OrganicFertilizerApplicationEvent1Payload
      | SyntheticFertilizerApplicationEvent1Payload
      | LimeApplicationEvent1Payload
      | BiosolidsApplicationEvent1Payload
      | BiodigestateApplicationEvent1Payload
      | CompostApplicationEvent1Payload
      | OtherApplicationEvent1Payload,
    fertilizerApplicationDate: new Date(
      fertilizerEventFormData.fertilizerApplicationDate
    ).toISOString(),

    totalAmount: fertilizerEventFormData.totalAmount,
    totalAmountUnit: fertilizerEventFormData.totalAmountUnit,
  };
};

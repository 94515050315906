import {
  Container,
  List,
  ListItem,
  Divider,
  Button,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { mixpanel } from "common/analytics";
import { useAppSelector } from "model";
import FertilizerEventList from "./FertilizerEventList";

export default function FertilizerDetail() {
  const history = useHistory();
  const { t } = useTranslation();
  const fieldsLoading = useAppSelector((state) => state.fields.loading);
  const arableLoading = useAppSelector((state) => state.arableFields.loading);
  const fertilizerEventsLoading = useAppSelector(
    (state) => state.fertilizerEvents.loading
  );
  console.log(fieldsLoading);
  console.log(arableLoading);
  console.log(fertilizerEventsLoading);

  if (
    fieldsLoading !== "idle" ||
    arableLoading !== "idle" ||
    fertilizerEventsLoading !== "idle"
  ) {
    return <LinearProgress />;
  }

  return (
    <>
      <Container>
        <List>
          <ListItem key="add-fertilizer-event">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Add new fertilizer event clicked");
                history.push(`/enter-fertilizer-events`);
              }}
            >
              {t("fertilizerEventDetailView.button.addFertilizerEvent.title")}
            </Button>
          </ListItem>
        </List>
      </Container>
      <Divider />
      <FieldEventListContainer>
        <FertilizerEventList />
      </FieldEventListContainer>
    </>
  );
}

const FieldEventListContainer = styled(Container)`
  flex-grow: 1;
`;

import { MutableRefObject } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import { Theme, Drawer as MuiDrawer } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { PrivateRoute } from "app";
import { FarmView } from "features/farmOverview";
import { FieldView, FieldInput } from "features/fieldDetail";
import { useAppSelector } from "model";
import AnimalGroupInput from "features/animalGroupDetail/AnimalGroupInput";
import AnimalGroupDetail from "features/animalGroupDetail/AnimalGroupDetail";
import { FarmInput } from "features/farmOverview/FarmInput";
import DrawerToolbar from "./DrawerToolbar";
import { Covers } from "features/bulkCoverInput";
import { GrassTypes } from "features/bulkGrassTypeInput";
import { ReseedEvents } from "features/bulkReseedEventInput";
import { MapRef } from "react-map-gl";
import { UpdateBiomassAfterMoveInDialog } from "features/singleCoverInput";
import { ReseedingDetail } from "features/reseedingDetail";
import InitialSelectScreen from "features/initialSelectScreen/InitialSelectScreen";
import InputsAndOutputs from "features/InputsAndOutputs/InputsAndOutputs";
import FeedPurchaseDetail from "features/feedPurchaseDetail/FeedPurchaseDetail";
import FeedPurchaseEvents from "features/feedPurchaseDetail/FeedPurchaseEvents";
import FeedEvents from "features/feedPurchaseDetail/FeedEvents";
import YieldEvents from "features/yieldInput/YieldEvents";
import YieldDetail from "features/yieldInput/YieldDetail";
import FertilizerDetail from "features/fertilizerInput/FertilizerDetail";
import FertilizerEvents from "features/fertilizerInput/FertilizerEvents";

const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflow: "visible",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      overflow: "visible",
    },
    drawerContent: {
      overflowX: "hidden",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface DrawerProps {
  mapRef: MutableRefObject<MapRef | null>;
}

export default function Drawer({ mapRef }: DrawerProps) {
  const classes = useStyles();
  const open = useAppSelector((state) => state.app.drawerOpen);
  const initalScreenSelectMatch = useRouteMatch("/initial-screen-select");

  return (
    <>
      <MuiDrawer
        PaperProps={{
          sx: {
            backgroundColor: initalScreenSelectMatch ? "#1C4321" : "white",
          },
        }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <DrawerToolbar />
        <UpdateBiomassAfterMoveInDialog />
        <div className={classes.drawerContent}>
          <Switch>
            <PrivateRoute path="/farm/:farmId" exact children={<FarmView />} />
            <PrivateRoute
              path="/add-farm"
              children={<FarmInput mapRef={mapRef} />}
            />
            <PrivateRoute
              path="/field/:fieldId"
              exact
              children={<FieldView />}
            />
            <PrivateRoute
              path="/edit-field/:fieldId"
              children={<FieldInput />}
            />
            <PrivateRoute path="/add-field" children={<FieldInput />} />
            <PrivateRoute
              path="/animal-group/:animalGroupId"
              children={<AnimalGroupDetail />}
            />
            <PrivateRoute
              path="/edit-animal-group/:animalGroupId"
              exact
              children={<AnimalGroupInput />}
            />
            <PrivateRoute
              path="/add-animal-group"
              exact
              children={<AnimalGroupInput />}
            />
            <PrivateRoute path="/add-covers" children={<Covers />} />
            <PrivateRoute path="/enter-grass-types" children={<GrassTypes />} />
            <PrivateRoute
              path="/enter-reseed-events"
              children={<ReseedEvents />}
            />
            <PrivateRoute
              path="/initial-screen-select"
              children={<InitialSelectScreen />}
            />
            <PrivateRoute
              path="/inputs-and-outputs"
              children={<InputsAndOutputs />}
            />
            <PrivateRoute
              path="/grass-types-reseeding-detail"
              children={<ReseedingDetail />}
            />
            <PrivateRoute
              path="/feed-overview"
              children={<FeedPurchaseDetail />}
            />
            <PrivateRoute path="/yield-overview" children={<YieldDetail />} />
            <PrivateRoute
              path="/enter-yield-events"
              children={<YieldEvents />}
            />
            <PrivateRoute
              path="/enter-feed-purchase-events"
              children={<FeedPurchaseEvents />}
            />
            <PrivateRoute path="/enter-feed-events" children={<FeedEvents />} />
            <PrivateRoute
              path="/fertilizer-overview"
              children={<FertilizerDetail />}
            />
            <PrivateRoute
              path="/enter-fertilizer-events"
              children={<FertilizerEvents />}
            />
          </Switch>
        </div>
      </MuiDrawer>
    </>
  );
}

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import {
  MACHINERY,
  RootState,
  getAllReseedEventsForFarm,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { GRASS_TYPE } from "model/fieldSlice";
import {
  SEEDING_METHOD,
  createReseedsEventForFarm,
} from "model/reseedEventSlice";
import EnterReseedEvents from "./EnterReseedEvents";
import { NameAndGuid } from "features/general/NameAndGuidMultiSelectDropdown";

const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function ReseedEvents() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  const handleClose = () => {
    history.goBack();
  };

  const [newGrassType, setNewGrassType] = useState<GRASS_TYPE | undefined>(
    undefined
  );

  const [grassTypeError, setGrassTypeError] = useState<boolean>(false);

  const [seedingMethod, setSeedingMethod] = useState<
    SEEDING_METHOD | undefined
  >(undefined);

  const [seedingMethodError, setSeedingMethodError] = useState<boolean>(false);

  const [notes, setNotes] = useState<string>("");

  const [selectedFields, setSelectedFields] = useState<NameAndGuid[]>([]);

  const [reseedDate, setReseedDate] = useState<Date | null>(new Date());

  const [reseedDateError, setReseedDateError] = useState<boolean>(false);

  const [machinery, setMachinery] = useState<MACHINERY[]>([]);

  const [fuel, setFuel] = useState<number>("" as unknown as number);

  const [fieldsSelectInputError, setFieldsSelectInputError] =
    useState<boolean>(false);

  async function handleSave() {
    // check for wrong input
    if (!newGrassType) {
      setGrassTypeError(true);
    }
    if (!reseedDate) {
      setReseedDateError(true);
    }

    if (selectedFields.length === 0) {
      setFieldsSelectInputError(true);
    }
    if (!seedingMethod) {
      setSeedingMethodError(true);
    }

    if (
      !newGrassType ||
      !reseedDate ||
      !seedingMethod ||
      reseedDateError ||
      selectedFields.length === 0 ||
      (fuel && fuel < 0)
    ) {
      return;
    }

    await createReseedsEventForFarm({
      note: notes,
      reseedDate: new Date(reseedDate).toISOString(),
      newGrassType: newGrassType,
      seedingMethod: seedingMethod,
      machinery: machinery,
      fuel: fuel,
      fieldGuids: selectedFields.map(
        (selectedField: NameAndGuid) => selectedField.guid ?? ""
      ),
    });
    await dispatch(getAllReseedEventsForFarm({ farmId: currentFarmId ?? "" }));
    notify(t("updateGrassTypes.success"));
    mixpanel.track("Save reseed events successful");
    handleClose();
  }

  return (
    <Dialog fullScreen disableEscapeKeyDown open onClose={handleClose}>
      <StyledContainer>
        <EnterReseedEvents
          newGrassType={newGrassType}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          setNewGrassType={setNewGrassType}
          handleSave={handleSave}
          setNotes={setNotes}
          notes={notes}
          reseedDate={reseedDate}
          setReseedDate={setReseedDate}
          grassTypeError={grassTypeError}
          reseedDateError={reseedDateError}
          setGrassTypeError={setGrassTypeError}
          setSeedingMethod={setSeedingMethod}
          seedingMethodError={seedingMethodError}
          setSeedingMethodError={setSeedingMethodError}
          setReseedDateError={setReseedDateError}
          fieldsSelectInputError={fieldsSelectInputError}
          setFieldsSelectInputError={setFieldsSelectInputError}
          seedingMethod={seedingMethod}
          machinery={machinery}
          setMachinery={setMachinery}
          fuel={fuel}
          setFuel={setFuel}
          enableFieldSelection={true}
          onCancel={() => {
            mixpanel.track("Enter re-seed event cancelled");
            handleClose();
          }}
        />
      </StyledContainer>
    </Dialog>
  );
}

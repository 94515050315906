import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityId,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { RootState } from "./rootReducer";
import {
  FertilizerEvent,
  FertilizerEventPostActionBody,
} from "./fertilizerEventTypes";
import { BEGINNING_OF_TIME } from "model";

export const FERTILIZER_EVENT_REJECTION_PERIOD_DAYS = 30;

class FertilizerAPI extends API<FertilizerEvent> {
  async getAllFertilizerEventsForFarm(
    farmGuid: string,
    fertilizerApplicationTimeAfter?: string | undefined
  ): Promise<FertilizerEvent[]> {
    const fertilizerApplicationTimeAfterParam = fertilizerApplicationTimeAfter
      ? `&fertilizerApplicationTimeAfter=${fertilizerApplicationTimeAfter}`
      : "";
    const res = await fetch(
      `${this.baseUrl}fertilizerApplication?farmGuid=${farmGuid}${fertilizerApplicationTimeAfterParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as FertilizerEvent[];
  }

  async createFertilizerEvent(
    fertilizerEvent: FertilizerEventPostActionBody
  ): Promise<string[]> {
    const res = await fetch(`${this.baseUrl}fertilizerApplication`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fertilizerEvent),
    });
    return (await res.json()) as string[];
  }
}

const fertilizerEventApi = new FertilizerAPI("fertilizerApplication");

export const createFertilizerEvent = createAsyncThunk<
  Promise<void>,
  { farmGuid: string; fertilizerEvent: FertilizerEventPostActionBody }
>(
  "fertilizerEvent/getFertilizerEventsBulk",
  async (
    {
      fertilizerEvent,
      farmGuid,
    }: { fertilizerEvent: FertilizerEventPostActionBody; farmGuid: string },
    { dispatch }
  ) => {
    await fertilizerEventApi.createFertilizerEvent(fertilizerEvent);
    await dispatch(
      getAllFertilizerEventsForFarm({
        farmGuid: farmGuid,
        fertilizerApplicationTimeAfter: BEGINNING_OF_TIME,
      })
    );
  }
);

export const updateFertilizerEvent = createAsyncThunk(
  "fertilizerEvent/update",
  async ({ fertilizerEvent }: { fertilizerEvent: FertilizerEvent }) => {
    const result = await fertilizerEventApi.updateOne(fertilizerEvent);
    return { ...fertilizerEvent, ...result };
  }
);

export const fertilizerEventAdapter = createEntityAdapter<FertilizerEvent>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (fertilizerEvent: FertilizerEvent) => fertilizerEvent.guid,
  // Keeps the array sorted by guid
  sortComparer: (a: FertilizerEvent, b: FertilizerEvent) =>
    a.guid.localeCompare(b.guid),
});

export const { selectAll: selectAllFertilizerEvents } =
  fertilizerEventAdapter.getSelectors(
    (state: RootState) => state.fertilizerEvents
  );

export const getAllFertilizerEventsForFarm = createAsyncThunk<
  Promise<Array<FertilizerEvent>>,
  { farmGuid: string; fertilizerApplicationTimeAfter?: string }
>(
  "fertilizerEvent/getFertilizerEventsBulk",
  async ({
    farmGuid,
    fertilizerApplicationTimeAfter,
  }: {
    farmGuid: string;
    fertilizerApplicationTimeAfter?: string | undefined;
  }) => {
    const res = await fertilizerEventApi.getAllFertilizerEventsForFarm(
      farmGuid,
      fertilizerApplicationTimeAfter
    );
    return res;
  }
);

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";
type FertilizerEventState = EntityState<FertilizerEvent> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialFertilizerEventState: FertilizerEventState =
  fertilizerEventAdapter.getInitialState({
    loading: "idle",
    error: null,
    status: null,
  });

const fertilizerEventSlice = createSlice({
  name: "fertilizerEvent",
  initialState: initialFertilizerEventState,
  reducers: {
    resetFertilizerEventEntries: () => initialFertilizerEventState,
  },
  extraReducers: {
    [getAllFertilizerEventsForFarm.fulfilled.type]: (state, action) => {
      if (action.payload) {
        // I do not fully understand why this is called with undefined
        // has to do something with how createFertilizerEvent is called inside dispatch
        fertilizerEventAdapter.setAll(
          state,
          action.payload as
            | readonly FertilizerEvent[]
            | Record<EntityId, FertilizerEvent>
        );
      }
    },
    [updateFertilizerEvent.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [updateFertilizerEvent.fulfilled.type]: (
      state,
      action: PayloadAction<FertilizerEvent>
    ) => {
      fertilizerEventAdapter.upsertOne(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [updateFertilizerEvent.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
  },
});
export const { resetFertilizerEventEntries } = fertilizerEventSlice.actions;
export const fertilizerEventReducer = fertilizerEventSlice.reducer;

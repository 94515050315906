import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
  EntityState,
  createAction,
} from "@reduxjs/toolkit";
import { center, Feature, FeatureCollection, Point } from "@turf/turf";
import {
  processFieldResponse,
  prepareFieldRequest,
  coverInKgPerHaToDryMatter,
} from "common/utils/helpers";
import API from "common/utils/Api";
import {
  RootState,
  selectAnimalGroupById,
  createOneReading,
  ManualDMInputResponse,
  EventType,
  getReadingsForField,
} from "model";
import { selectTmpDeletedFeatures } from "./appSlice";
import { sum } from "lodash";
import { getAnimalGroupColorMapping } from "./animalGroupSlice";
import { mixpanel } from "common/analytics";

const fieldApi = new API<FieldResponse>("fields");

type Visibility = "none" | "visible";

export type FieldResponse = {
  guid: string;
  name: string;
  farm: string;
  geom: FeatureCollection;
  area: number;
  tiles?: Array<string>;
  dryMatterNow: number;
  animalGroup?: string;
  animalGroupNow?: string;
};

export type Field = {
  guid: string;
  name: string;
  farm: string;
  geom: Feature;
  area: number;
  invalidatedTimestamp?: number;
  tiles?: Array<string>;
  visibility?: Visibility;
  dryMatterNow: number;
  animalGroup?: string;
  animalGroupNow?: string;
  grassType?: GRASS_TYPE;
};

export type FieldUpdate = {
  guid: string;
  name?: string;
  farm?: string;
  geom?: Feature;
  area?: number;
  invalidatedTimestamp?: number;
  visibility?: Visibility;
  dryMatterNow?: number;
  animalGroup?: string;
  grassType?: GRASS_TYPE;
};

export enum GRASS_TYPE {
  PERMANENT_PASTURE = "PERMANENT_PASTURE",
  HERBAL_LEY = "HERBAL_LEY",
  RYE_GRASS = "RYE_GRASS",
  RYE_GRASS_AND_CLOVER = "RYE_GRASS_AND_CLOVER",
  WILDFLOWER_MEADOW = "WILDFLOWER_MEADOW",
  LUCERNE_SAINFOIN = "LUCERNE_SAINFOIN",
  BRASSICAS = "BRASSICAS",
  OTHER = "OTHER",
}

export const fieldAdapter = createEntityAdapter<Field>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (field) => field.guid,
  // Keeps the array sorted by guid
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const {
  selectAll: selectAllFields,
  selectById: selectFieldById,
  selectEntities: selectFieldEntities,
  selectIds: selectFieldIds,
} = fieldAdapter.getSelectors((state: RootState) => state.fields);

//TODO: compute on backend
export const mapFieldsCentersById = createSelector(
  selectAllFields,
  (fields) => {
    const centerByGuid: Record<string, Feature<Point>> = {};

    fields.forEach((field) => {
      centerByGuid[field.guid] = center(field.geom);
    });

    return centerByGuid;
  }
);

export const fieldWithAnimalsCenters = createSelector(
  selectAllFields,
  getAnimalGroupColorMapping,
  mapFieldsCentersById,
  (fields, colorByAnimalGroupId, fieldCentersById) => {
    return fields
      .filter((field) => field.animalGroup && field.animalGroupNow)
      .map((field) => ({
        ...field,
        center: fieldCentersById[field.guid],
        color: colorByAnimalGroupId[field.animalGroup as string],
      }));
  }
);

export const getAllFieldsForAnimalGroupId = (animalGroupId?: string) =>
  createSelector(selectAllFields, (fields) => {
    return fields.filter((field) => field.animalGroup === animalGroupId);
  });

export const getAllFieldsForSilage = createSelector(
  selectAllFields,
  (fields) => {
    return fields.filter((field) => !field.animalGroup);
  }
);

export const getAllInvalidatedFields = createSelector(
  selectAllFields,
  (fields) => {
    return fields.filter((field) => field.invalidatedTimestamp);
  }
);

export const getAvailableDryMatterForAnimalGroupId = (animalGroupId?: string) =>
  createSelector(
    getAllFieldsForAnimalGroupId(animalGroupId),
    (state: RootState) => selectAnimalGroupById(state, animalGroupId as string),
    (fields, animalGroup) => {
      return sum(
        fields.map((field) =>
          coverInKgPerHaToDryMatter(
            field.dryMatterNow - (animalGroup?.postGrazingTarget || 0),
            field.area
          )
        )
      );
    }
  );

export const getAverageCoverForSilage = createSelector(
  getAllFieldsForSilage,
  (fields) =>
    sum(
      fields.map((field) =>
        coverInKgPerHaToDryMatter(field.dryMatterNow, field.area)
      )
    ) / sum(fields.map((field) => field.area / 10000))
);

export const getAllFieldGeoms = createSelector(selectAllFields, (fields) => {
  const features = fields.map((f) => f.geom);
  return {
    type: "FeatureCollection",
    features: features,
  } as FeatureCollection;
});

export const getFieldGeomsForAnimalGroup = (animalGroup: string) =>
  createSelector(getAllFieldsForAnimalGroupId(animalGroup), (fields) => {
    const features = fields.map((f) => f.geom);
    return {
      type: "FeatureCollection",
      features: features,
    } as FeatureCollection;
  });

export const getTotalArea = createSelector(selectAllFields, (fields) =>
  fields.reduce((acc, field) => {
    if (!field.invalidatedTimestamp) {
      acc += field.area / 10000;
    }
    return acc;
  }, 0)
);

export const getTotalAvgDM = createSelector(
  selectAllFields,
  getTotalArea,
  (fields, totalArea) => {
    const sumOfDM = fields.reduce((acc, field) => {
      if (!field.invalidatedTimestamp) {
        acc += (field.dryMatterNow * field.area) / 10000;
      }
      return acc;
    }, 0);
    return Math.round(sumOfDM / totalArea);
  }
);

export const getLatestFieldTiles = createSelector(selectAllFields, (fields) => {
  const tileIds = fields.map((f) => {
    return f.tiles?.slice(-1)[0];
  });
  return tileIds.filter((t) => t);
});

export const getFieldsWithNoTiles = createSelector(selectAllFields, (fields) =>
  fields.reduce((acc: Array<string>, field) => {
    if (field.tiles?.length === 0) {
      acc.push(field.guid);
    }
    return acc;
  }, [])
);

// hide features that are being edited and boundary removed;
export const getFieldsWithNoTilesForNotDeletedFieldBoundaries = createSelector(
  getFieldsWithNoTiles,
  selectTmpDeletedFeatures,
  (fields, tmpDeletedFeatures) =>
    fields.filter((id) => tmpDeletedFeatures.indexOf(id) === -1)
);

export const getFieldsCenterWithNoTilesForNotDeletedFieldBoundaries =
  createSelector(
    selectAllFields,
    selectTmpDeletedFeatures,
    mapFieldsCentersById,
    (fields, tmpDeletedFeatures, fieldCentersById) => {
      return fields
        .filter(
          (field) =>
            field.tiles?.length === 0 &&
            tmpDeletedFeatures.indexOf(field.guid) === -1
        )
        .map((field) => ({
          guid: field.guid,
          center: fieldCentersById[field.guid],
        }));
    }
  );

// hide features that are being edited and boundary removed;
export const selectAllFieldsWithNotDeletedBoundaries = createSelector(
  selectAllFields,
  selectTmpDeletedFeatures,
  (fields, tmpDeletedFeatures) =>
    fields.filter((field) => tmpDeletedFeatures.indexOf(field.guid) === -1)
);

export const getFieldWithAnimalsForAnimalGroupId = (animalGroupId?: string) =>
  createSelector(getAllFieldsForAnimalGroupId(animalGroupId), (fields) =>
    animalGroupId
      ? fields.filter((field) => !!field.animalGroupNow).pop()
      : undefined
  );

export const fetchFieldsById = createAsyncThunk<
  Promise<Array<FieldResponse>>,
  Array<string>
>("fields/fetchById", (fieldIds: Array<string>) => fieldApi.getMany(fieldIds));

type CreateFieldThunkInput = {
  field: Field;
};

export const createField = createAsyncThunk<
  Promise<FieldResponse>,
  CreateFieldThunkInput
>("fields/create", async ({ field }) => {
  const res = await fieldApi.createOne(prepareFieldRequest(field));

  const newField = await fieldApi.getOne(res.guid);

  return newField;
});

export const updateField = createAsyncThunk<Promise<any>, Field>(
  "fields/update",
  async (field, { dispatch }) => {
    await fieldApi.updateOne(prepareFieldRequest(field));
    dispatch(
      fieldSlice.actions.changeFieldVisibility({
        guid: field.guid,
        visibility: "visible",
      })
    );
    return fieldApi.getOne(field.guid);
  }
);

const makeTileInvisible = createAction<string>("tile/madeInvisible");

export const deleteField = createAsyncThunk<Promise<any>, Field>(
  "fields/delete",
  async (field, { dispatch }) => {
    const res = await fieldApi.deleteOne(field.guid);
    if (res.ok) {
      dispatch(fieldSlice.actions.removeField(field.guid));
      field.tiles?.forEach((tileId) => dispatch(makeTileInvisible(tileId)));
    }
    return res.status;
  }
);

export const moveIntoField = createAsyncThunk<
  Promise<any>,
  { currentFieldGuid?: string; newFieldGuid: string; dateTimeIn: Date }
>(
  "fields/moveIn",
  async ({ currentFieldGuid, newFieldGuid, dateTimeIn }, { dispatch }) => {
    dispatch(
      optimisticFieldMoveIn({
        newFieldGuid,
        currentFieldGuid,
      })
    );
    const moveInEvent: ManualDMInputResponse = {
      guid: "new",
      manualDryMatterInput: {
        field: newFieldGuid,
        dateTimeMeasurementStart: dateTimeIn.toISOString(),
        dateTimeMeasurementEnd: dateTimeIn.toISOString(),
        event: {
          eventType: EventType.GrazingEvent,
          dateTimeIn: dateTimeIn.toISOString(),
        },
        kind: "user",
        verificationStatus: "Accepted",
      },
    };

    await dispatch(createOneReading(moveInEvent));

    const fieldsToFetch = [newFieldGuid];
    if (currentFieldGuid) {
      fieldsToFetch.push(currentFieldGuid);
    }

    void Promise.all(
      fieldsToFetch.map((id) => dispatch(getReadingsForField(id)))
    );
    await dispatch(fetchFieldsById(fieldsToFetch));
  }
);

export const getFieldCount = createSelector(
  selectAllFields,
  (fields) => fields.length
);

export const optimisticFieldMoveIn = createAction<{
  currentFieldGuid?: string;
  newFieldGuid: string;
}>("map/optimisticFieldMoveIn");

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";

type FieldState = EntityState<Field> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialState: FieldState = fieldAdapter.getInitialState({
  loading: "idle",
  error: null,
  status: null,
});

const fieldSlice = createSlice({
  name: "fields",
  initialState,
  reducers: {
    resetFields: () => initialState,
    changeFieldVisibility: (state, action: PayloadAction<FieldUpdate>) => {
      if (state.entities[action.payload.guid]) {
        state.entities[action.payload.guid]!.visibility =
          action.payload.visibility;
      }
    },
    changeGrassType: (state, action: PayloadAction<FieldUpdate>) => {
      if (state.entities[action.payload.guid]) {
        state.entities[action.payload.guid]!.grassType =
          action.payload.grassType;
      }
    },
    removeField: (state, action: PayloadAction<string>) =>
      fieldAdapter.removeOne(state, action.payload),
    invalidateFieldData: (state, action: PayloadAction<string>) => {
      mixpanel.track("Field invalidated");
      mixpanel.time_event("Field validated");
      fieldAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          invalidatedTimestamp: Date.now() / 1000,
          tiles: [],
        },
      });
    },
    validateFieldData: (state, action: PayloadAction<string>) => {
      mixpanel.track("Field validated", { guid: action.payload }); // stop the timer
      mixpanel.track("Field validation timeout", { guid: action.payload });
      fieldAdapter.updateOne(state, {
        id: action.payload,
        changes: { invalidatedTimestamp: undefined },
      });
    },
  },
  extraReducers: {
    "fields/animalGroupDeleted": (state, action: PayloadAction<string>) => {
      Object.values(state.entities).forEach((entity) => {
        if (entity?.animalGroup === action.payload) {
          entity.animalGroup = undefined;
        }
      });
    },
    [fetchFieldsById.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [fetchFieldsById.fulfilled.type]: (
      state,
      action: PayloadAction<Array<FieldResponse>>
    ) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
        action.payload.forEach((f) => {
          if (state.entities[f.guid]?.invalidatedTimestamp) {
            if (f.tiles && f.tiles.length > 0) {
              delete state.entities[f.guid]?.invalidatedTimestamp;
              mixpanel.track("Field validated", { guid: f.guid });
            }
          }
        });

        // Always update fields with the latest data, even if they have no tiles
        fieldAdapter.upsertMany(state, processFieldResponse(action.payload));
      }
    },
    [fetchFieldsById.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
        state.status = "error";
      }
    },
    [createField.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createField.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
        fieldAdapter.upsertMany(state, processFieldResponse([action.payload]));
      }
    },
    [createField.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
    [updateField.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
      if (state.entities[action.meta.arg.guid]) {
        // optimistic update of the geometry
        state.entities[action.meta.arg.guid]!.geom = action.meta.arg.geom;
      }
    },
    [updateField.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
      }
      fieldAdapter.upsertMany(state, processFieldResponse([action.payload]));
    },
    [updateField.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
    [deleteField.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [deleteField.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
      }
    },
    [deleteField.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
        state.status = "error";
      }
    },
    [optimisticFieldMoveIn.type]: (state, action) => {
      const { newFieldGuid, currentFieldGuid } = action.payload;
      const newField = state.entities[newFieldGuid];
      if (currentFieldGuid) {
        const currentField = state.entities[currentFieldGuid];
        if (
          newField && // validate that current and new fields have the same animal group
          currentField &&
          newField.animalGroup &&
          newField.animalGroup === currentField.animalGroup &&
          currentField.animalGroupNow === currentField.animalGroup
        ) {
          newField.animalGroupNow = newField.animalGroup;
          currentField.animalGroupNow = undefined;
        }
      } else if (newField) {
        newField.animalGroupNow = newField.animalGroup;
      }
    },
  },
});

export const {
  changeFieldVisibility,
  changeGrassType,
  invalidateFieldData,
  validateFieldData,
  resetFields,
} = fieldSlice.actions;

export const fieldReducer = fieldSlice.reducer;

import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import {
  useAppSelector,
  GeneralFeedPurchaseEvent,
  FEED_PURCHASE_EVENT_REJECTION_PERIOD_DAYS,
  GeneralFeedPurchase,
} from "model";
import { useTranslation } from "common/locales";
import { getFormattedDate } from "../../common/utils/getFormattedTime";
import { AddFeedPurchaseIcon } from "common/icons";
import { MoreVert } from "@mui/icons-material";

import { useState, Fragment } from "react";
import UpdateFeedPurchaseEventDialog from "./UpdateFeedPurchaseEventDialog";
import { convertToFeedStockAmountString } from "./generalMethods";

export default function FeedPurchaseEventListItem({
  feedPurchaseEvent,
}: {
  feedPurchaseEvent: GeneralFeedPurchaseEvent;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);

  const icon = <AddFeedPurchaseIcon />;

  const { feedPurchaseEventDate, feedPurchases } = feedPurchaseEvent;
  const formattedTime = getFormattedDate(feedPurchaseEventDate, locale);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  const canEdit =
    differenceInDays(
      Date.now(),
      feedPurchaseEvent.creationDate
        ? new Date(feedPurchaseEvent.creationDate)
        : Date.now()
    ) <= FEED_PURCHASE_EVENT_REJECTION_PERIOD_DAYS;

  return (
    <ListItem key={`re-listitem-${feedPurchaseEvent.guid}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" display="block">
            {t("feedPurchaseEvent.feedPurchaseDate", {
              feedPurchasedDate: formattedTime,
            })}
          </Typography>
        }
        secondary={
          <>
            {feedPurchases.map((feedPurchase: GeneralFeedPurchase) => {
              return (
                <Fragment key={`re-listitem-${feedPurchase.feedType}`}>
                  <Typography component="span" display="block">
                    {t(`feedPurchase.feedType.option.${feedPurchase.feedType}`)}
                  </Typography>
                  {
                    <Typography component="span" display="block">
                      {feedPurchase.cost
                        ? feedPurchase.cost.toLocaleString(locale, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          }) + " £"
                        : "price unknown"}
                    </Typography>
                  }
                  <Typography component="span" display="block">
                    {convertToFeedStockAmountString(
                      feedPurchase.amount,
                      locale,
                      feedPurchase.unitType,
                      t
                    )}
                  </Typography>
                </Fragment>
              );
            })}
          </>
        }
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={openMenu}
            id={feedPurchaseEvent.guid}
            size="large"
          >
            <MoreVert />
          </IconButton>
          <UpdateFeedPurchaseEventDialog
            closeMenu={closeMenu}
            menuAnchor={menuAnchor}
            feedPurchaseEvent={feedPurchaseEvent}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

import { useHistory } from "react-router-dom";
import { Container, Dialog } from "@mui/material";

import styled from "styled-components";
import EnterFertilizerEvent from "./EnterFertilizerEvent";
import {
  FertilizerEventPostActionBody,
  createFertilizerEvent,
  useAppDispatch,
} from "model";
import { useNotification } from "app/NotificationProvider";
import { mixpanel } from "common/analytics/mixpanel";
import { useTranslation } from "common/locales";
import { FertilizerEventFormData } from "./FertilizerEventFormData";
import { convertFromFormInput } from "./FertilizerEventFormData";

export const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function FertilizerEvents() {
  const dispatch = useAppDispatch();

  const { notify } = useNotification();
  const history = useHistory();
  const handleClose = () => {
    history.goBack();
  };
  const { t } = useTranslation();

  async function handleSave(
    fertilizerEventFormData: FertilizerEventFormData,
    farmGuid: string | undefined
  ) {
    const fertilizerEventBody = convertFromFormInput(fertilizerEventFormData);
    const postBody: FertilizerEventPostActionBody = {
      ...fertilizerEventBody,
      fieldGuids: fertilizerEventFormData.fieldGuids,
    };
    farmGuid = farmGuid ?? "";
    await dispatch(
      createFertilizerEvent({
        fertilizerEvent: postBody,
        farmGuid,
      })
    );

    notify(t("addFertilizerEvent.add.success"));
    mixpanel.track("FertilizerEvent created");

    handleClose();
  }
  return (
    <Dialog fullScreen disableEscapeKeyDown open onClose={handleClose}>
      <StyledContainer>
        <EnterFertilizerEvent
          fertilizerEvent={undefined}
          handleSave={handleSave}
          handleClose={() => {
            handleClose();
          }}
          fieldSelectDisabled={false}
        />
      </StyledContainer>
    </Dialog>
  );
}

import { Dispatch, SetStateAction, useState } from "react";
import { Container, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import {
  MACHINERY,
  RootState,
  getAllReseedEventsForFarm,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { GRASS_TYPE, fetchFieldsById } from "model/fieldSlice";
import { SEEDING_METHOD } from "model/reseedEventSlice";
import { updateReseedEvent, Field, ReseedEvent } from "model";
import { useIntl } from "react-intl";
import EnterReseedEvents from "features/bulkReseedEventInput/EnterReseedEvents";
import { NameAndGuid } from "features/general/NameAndGuidMultiSelectDropdown";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function UpdateReseedEvent({
  field,
  reseedEvent,
  dialogOpen,
  setDialogOpen,
}: {
  field: Field;
  reseedEvent: ReseedEvent;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [newGrassType, setNewGrassType] = useState<GRASS_TYPE | undefined>(
    reseedEvent.newGrassType
  );

  const [grassTypeError, setGrassTypeError] = useState<boolean>(false);

  const [seedingMethod, setSeedingMethod] = useState<
    SEEDING_METHOD | undefined
  >(reseedEvent.seedingMethod);

  const [seedingMethodError, setSeedingMethodError] = useState<boolean>(false);

  const [selectedFields, setSelectedFields] = useState<NameAndGuid[]>([
    { name: field.name, guid: field.guid },
  ]);

  const [notes, setNotes] = useState<string>(reseedEvent.note ?? "");

  const [reseedDate, setReseedDate] = useState<Date | null>(
    new Date(Date.parse(reseedEvent.reseedDate))
  );

  const [reseedDateError, setReseedDateError] = useState<boolean>(false);

  const [machinery, setMachinery] = useState<MACHINERY[]>(
    reseedEvent.machinery ?? []
  );

  const [fuel, setFuel] = useState<number>(
    reseedEvent.fuel ?? ("" as unknown as number)
  );

  const [fieldsSelectInputError, setFieldsSelectInputError] =
    useState<boolean>(false);

  function callUpdateReseedEvent() {
    if (!newGrassType || !reseedDate || !seedingMethod) {
      return;
    }

    return dispatch(
      updateReseedEvent({
        fieldGuid: field.guid,
        newGrassType: newGrassType,
        guid: reseedEvent.guid,
        note: notes ?? "",
        seedingMethod: seedingMethod,
        reseedDate: reseedDate.toISOString(),
        fuel: fuel,
        machinery: machinery,
      })
    );
  }

  async function handleSave() {
    // check for wrong input
    if (!newGrassType) {
      setGrassTypeError(true);
    }
    if (!reseedDate) {
      setReseedDateError(true);
    }
    if (!seedingMethod) {
      setSeedingMethodError(true);
    }

    if (
      !newGrassType ||
      !reseedDate ||
      !seedingMethod ||
      reseedDateError ||
      (fuel && fuel < 0)
    ) {
      return;
    }

    await callUpdateReseedEvent();
    await dispatch(fetchFieldsById([field.guid])); // updating the grass type if needed
    await dispatch(getAllReseedEventsForFarm({ farmId: currentFarmId ?? "" }));
    notify(t("editReseedEvent.success"));
    mixpanel.track("Update re seed event successful");
    handleClose();
  }
  const { formatMessage: f } = useIntl();

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={dialogOpen}
      onClose={handleClose}
    >
      <StyledContainer>
        <EnterReseedEvents
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          enableFieldSelection={false}
          setNewGrassType={setNewGrassType}
          setNotes={setNotes}
          reseedDate={reseedDate}
          setReseedDate={setReseedDate}
          grassTypeError={grassTypeError}
          reseedDateError={reseedDateError}
          setGrassTypeError={setGrassTypeError}
          setSeedingMethod={setSeedingMethod}
          seedingMethodError={seedingMethodError}
          setSeedingMethodError={setSeedingMethodError}
          setReseedDateError={setReseedDateError}
          newGrassType={newGrassType}
          notes={notes}
          seedingMethod={seedingMethod}
          machinery={machinery}
          setMachinery={setMachinery}
          fuel={fuel}
          setFuel={setFuel}
          handleSave={handleSave}
          fieldsSelectInputError={fieldsSelectInputError}
          setFieldsSelectInputError={setFieldsSelectInputError}
          onCancel={() => {
            mixpanel.track("Update re seed event cancelled");
            handleClose();
          }}
        />
      </StyledContainer>
    </Dialog>
  );
}
